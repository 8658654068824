@media (max-width: 959px) {
  .usp-l {
    display: none;
  }
}
@media (min-width: 960px) {
  .usp-m {
    display: none;
  }
}

/* CookieFirst banner styling */
.cf1f8N.cf1uIq {
  display: none !important;
}

.cf1y60 {
  font-weight: 400 !important;
}

.cf1lHZ.cf2MAH {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.cf1lHZ.cf2MAH > button:hover span {
  text-decoration: underline;
}

@media (min-width: 1480px) {
  div[data-cookiefirst-widget='banner'] {
    max-width: 1480px;
  }
}

.button-primary,
.button-secondary,
.button-neutral {
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  box-shadow:
    0px 0px 1.5px hsl(0deg 0% 0% / 0.15),
    0px 0.5px 0.6px hsl(0deg 0% 0% / 0.04),
    0px 1.7px 2.2px -0.7px hsl(0deg 0% 0% / 0.06),
    0px 5px 6.4px -1.5px hsl(0deg 0% 0% / 0.08);
  transition: background-color 0.2s ease;
  color: #fff;
  font-family:
    Signika,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Helvetica,
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji';
  font-weight: 700;
}

.button-primary:hover,
.button-secondary:hover,
.button-neutral:hover {
  text-decoration: none;
}

.button-primary {
  background-color: rgb(203, 135, 146);
}
.button-primary:hover {
  background-color: rgb(226, 185, 187);
}
.button-secondary {
  background-color: #4d7f71;
}
.button-secondary:hover {
  background: rgb(53, 88, 79);
}
.button-neutral {
  background-color: #fff;
  color: rgb(203, 135, 146);
}
.button-size-large {
  padding-block: calc(7.8px + 0.68vw);
  padding-inline: calc(15.6px + 1.36vw);
  /* vp-style: 22px-28px @ 320px-1200px */
  font-size: calc(19.8px + 0.68vw);
}

@media (min-width: 1200px) {
  .button-size-large {
    font-size: 28px;
    padding-block: 16px;
    padding-inline: 32px;
  }
}
